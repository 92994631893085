import {
  GET_TARIFFS_SUCCESS
} from '../constants';

export default function tariff(state = {}, action) {
  switch (action.type) {

    case GET_TARIFFS_SUCCESS:
      return {
        ...state,
        aviableTariffs: action.aviableTariffs,
      };
    default:
      return state;
  }
}
