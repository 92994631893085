import { gql } from "react-apollo";

import {
  GET_TARIFFS_SUCCESS,
  GET_TARIFFS_ERROR,
  GET_TARIFFS_START,
} from "../constants";

const query = gql`
  {
    getTariffs {
      id
      name
      description
      host_commision
      guest_commision
      host_is_percent
      guest_is_percent
      is_only_commission
    }
  }
`;

export function getTariffs() {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: GET_TARIFFS_START,
    });

    try {
      // Send Request to get listing data
      const { data } = await client.query({
        query,
        fetchPolicy: "network-only",
      });

      if (data && data.getTariffs) {
        dispatch({
          type: GET_TARIFFS_SUCCESS,
          aviableTariffs: data.getTariffs,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_TARIFFS_ERROR,
      });
      return false;
    }

    return true;
  };
}
