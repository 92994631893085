// General
import React, { Component } from "react";
import PropTypes from "prop-types";
// Redux Form
import { Field, reduxForm, formValueSelector } from "redux-form";
// Translation
import { injectIntl, FormattedMessage } from "react-intl";
// Redux
import { connect } from "react-redux";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from "classnames";

import s from "./ListPlaceStep1.css";
import bt from "../../components/commonStyle.css";

// Locale
import messages from "../../locale/messages";

// components
import FooterButton from "./FooterButton";
import SidePanel from "./SidePanel";

// Helpers
import validate from "./validate";

//Image
import toolTipIcon from "../../../public/SiteIcons/listCommonToolTip.svg";
import updateStep3 from "./updateStep3";

class AdditionalCategories extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    previousPage: PropTypes.any,
    nextPage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
      houseType: [],
      roomType: [],
      buildingSize: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { listingFields } = this.props;

    if (listingFields != undefined) {
      this.setState({
        termType: listingFields.termType,
        customerType: listingFields.customerType,
      });
    }
  }

  componentDidMount() {
    const { valid } = this.props;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { valid, listingFields } = nextProps;
    if (valid) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }

    if (listingFields != undefined) {
      this.setState({
        termType: listingFields.termType,
        customerType: listingFields.customerType,
      });
    }
  }

  renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
  }) => {
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <select {...input}>{children}</select>
        {touched && error && <span>{formatMessage(error)}</span>}
      </div>
    );
  };

  renderFormControlSelect = ({
    input,
    label,
    meta: { touched, error },
    children,
    className,
  }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormControl componentClass="select" {...input} className={className}>
          {children}
        </FormControl>
      </div>
    );
  };

  render() {
    const {
      handleSubmit,
      previousPage,
      nextPage,
      existingList,
      formPage,
      step,
    } = this.props;
    const { formatMessage } = this.props.intl;
    const { isDisabled, termType, customerType } = this.state;
    let path = "index";
    if (existingList) {
      path = "home";
    }

    return (
      <div>
        <div className={s.grid}>
          <SidePanel
            title={formatMessage(messages.stepOneCommonHeading)}
            landingContent={formatMessage(messages.whatKindOfPlaceListing)}
          />
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <div className={s.landingMainContent}>
                  <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                      <FormattedMessage {...messages.termTypes} />
                    </ControlLabel>
                    <Field
                      name="termType"
                      component={this.renderFormControlSelect}
                      className={cx(
                        s.formControlSelect,
                        s.listCommonJumboSelect,
                        "listCommonJumboSelectRTL"
                      )}
                    >
                      <option value={""}>Select</option>
                      <option value={"short"}>
                        {" "}
                        {formatMessage(messages.short)}{" "}
                      </option>
                      <option value={"long"}>
                        {" "}
                        {formatMessage(messages.long)}
                      </option>
                    </Field>
                  </FormGroup>
                  <FormGroup className={s.space5}>
                    <ControlLabel className={cx(s.landingLabel, "textWhite")}>
                      <FormattedMessage {...messages.customerTypes} />
                    </ControlLabel>
                    <Field
                      name="customerType"
                      component={this.renderFormControlSelect}
                      className={cx(
                        s.formControlSelect,
                        s.listCommonJumboSelect,
                        "listCommonJumboSelectRTL"
                      )}
                    >
                      <option value={""}>Select</option>
                      <option value={"student"}>
                        {" "}
                        {formatMessage(messages.student)}{" "}
                      </option>
                      <option value={"family"}>
                        {" "}
                        {formatMessage(messages.family)}{" "}
                      </option>
                      <option value={"worker"}>
                        {" "}
                        {formatMessage(messages.worker)}{" "}
                      </option>
                      <option value={"tourist"}>
                        {" "}
                        {formatMessage(messages.tourist)}{" "}
                      </option>
                    </Field>
                  </FormGroup>

                  <div className={s.tipCommonCss}>
                    <img src={toolTipIcon} />
                    <span className={cx(s.commonTipCsss, "textWhite")}>
                      <FormattedMessage {...messages.isPersonalHomeInfo} />
                    </span>
                  </div>
                </div>
                <FooterButton
                  isDisabled={isDisabled}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  nextPagePath={"home"}
                  previousPagePath={path}
                  formPage={formPage}
                  step={step}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

AdditionalCategories = reduxForm({
  form: "ListPlaceStep3", // a unique name for this form
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
  onSubmit: updateStep3,
})(AdditionalCategories);

// Decorate with connect to read form values
const selector = formValueSelector("ListPlaceStep3"); // <-- same as form name

const mapState = (state) => ({
  existingList: state.location.isExistingList,
  listingFields: state.listingFields.data,
});

const mapDispatch = {};

export default injectIntl(
  withStyles(s, bt)(connect(mapState, mapDispatch)(AdditionalCategories))
);
