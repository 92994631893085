function base64toFile(base64String, filename, mimeType) {
    // Convert the Base64 string to a Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
  
    // Create a File object from the Blob
    const file = new File([blob], filename, { type: mimeType });
  
    return file;
  }


  export default base64toFile