import React from "react";
import Layout from "../../components/Layout/Layout";
import Page from "../../components/Page/Page";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

class Content extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const { data, locale} = this.props;
    
    const language =
    {
      "en-US": "En",
      "ru-RU": "Ru",
      "uz-UZ": "",
    }[locale] || "";


    return (
      <Layout>
        <Page
          html={data.getEditStaticPage[`content${language}`]}
          title={data.getEditStaticPage[`metaTitle${language}`]}
        />
      </Layout>
    );
  }
}

const mapState = (state) => ({
  locale:state.intl.locale,
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(Content);
