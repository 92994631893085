import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

// Style
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Iframe.css";
import {
   Row,
 } from "react-bootstrap";
import cx from "classnames";
 // Redux
import { connect } from "react-redux";
 
 
// Locale
import messages from "../../../locale/messages";

 
class Iframe extends React.Component {
  static propTypes = {
    kuulaLink: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {}

  render() {
    const { kuulaLink } = this.props;

    return (
      <Row className={cx(s.pageContent, "viewListingMap")}>
        <div className={cx(s.space2, s.horizontalLineThrough)}>
          <h1 className={cx(s.sectionTitleText, s.space2)}>
            <FormattedMessage {...messages.tour360} />
          </h1>
        </div>
        <div className={cx(s.space2)}>
          <div style={{ height: 350 }}>
            <iframe
              width="100%"
              height="350px"
              allow="xr-spatial-tracking; gyroscope; accelerometer"
              allowFullScreen
              src={kuulaLink}
            ></iframe>
          </div>

          <div className={s.listingLine}></div>
        </div>
      </Row>
    );
  }
}

//export default withStyles(s)(Iframe);

const mapState = (state) => ({});

const mapDispatch = {
 };

 

export default withStyles(s)(connect(mapState, mapDispatch)(Iframe));
