import React from "react";
import PropTypes from "prop-types";
import { flowRight as compose } from "lodash";

import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./BannerCaption.css";
import { Grid, Row, Col } from "react-bootstrap";
import cx from "classnames";
import Loader from "../../Loader";
import { connect } from "react-redux";
class BannerCaption extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool,
      getBanner: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        content: PropTypes.string,
        titleEn: PropTypes.string,
        contentEn: PropTypes.string,
        titleRu: PropTypes.string,
        contentRu: PropTypes.string,
      }),
    }),
  };

  render() {
    const {
      title,
      content,
      titleEn,
      contentEn,
      titleRu,
      contentRu,
      locale,
    } = this.props;
    const banner = {
      title,
      content,
      titleEn,
      contentEn,
      titleRu,
      contentRu,
    };

    const language =
      {
        "en-US": "En",
        "ru-RU": "Ru",
        "uz-UZ": "",
      }[locale] || "";

    if (!(title && content)) {
      return <Loader type={"text"} />;
    } else {
      return (
        <Grid fluid>
          <div className={cx(s.bannerCaptionContainer)}>
            <h1 className={cx(s.noMargin, s.bannerCaptionText)}>
              <span className={s.bannerCaptionHighlight}>
                {banner[`title${language}`] || banner[`title`]}
              </span>{" "}
              {banner[`content${language}`] || banner[`content`]}
            </h1>
          </div>
        </Grid>
      );
    }
  }
}

const mapState = (state) => ({
  locale: state.intl.locale,
});

const mapDispatch = {};

export default compose(
  withStyles(s),
  connect(mapState, mapDispatch)
)(BannerCaption);
