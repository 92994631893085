import React from "react";
import Layout from "../../../components/Layout";
import Page from "../../../components/Page";
import fetch from "../../../core/fetch";
import cookie from "react-cookies";
import Content from "../Content";

const query = `query getEditStaticPage ($id: Int!) {
  getEditStaticPage (id: $id) {
      id
      pageName
      content
      metaTitle
      metaDescription
      contentEn
      metaTitleEn
      metaDescriptionEn
      contentRu
      metaTitleRu
      metaDescriptionRu      
      createdAt
  }
}`;

export default async function action({ locale, store, ...rest }) {
  const dataResult = await new Promise((resolve) => {
    require.ensure([], (require) => {
      resolve(require('./travelCredit.md'));
    }, 'travelCredit');
  });

  const resp = await fetch("/graphql", {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: query,
      variables: { id: 3 },
    }),
    credentials: "include",
  });

  const { data } = await resp.json();

  const language =
    {
      "en-US": "En",
      "ru-RU": "Ru",
      "uz-UZ": "",
    }[locale] || "";

  console.log(data.getEditStaticPage, locale, cookie.load("lang"));
  if (data && data.getEditStaticPage) {
    return {
      title: data.getEditStaticPage[`metaTitle${language}`]  || data.getEditStaticPage[`metaTitle`],
      description: data.getEditStaticPage[`metaDescription${language}`] || data.getEditStaticPage[`metaDescription`],
      component: <Content data={data} />,
    };
  } else {
    return {
      title: dataResult.title,
      component: (
        <Layout>
          <Page {...dataResult} />
        </Layout>
      ),
    };
  }
}
