exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MyId-scanner-1KRwh {\n  height: 100vh;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  gap: 5vh;\n  padding: 10vh 5vw;\n}\n.MyId-scanner-1KRwh .MyId-title-18qus {\n  text-align: center;\n}\n.MyId-scanner-1KRwh .MyId-iframe-r68_T {\n  border-radius: 50%;\n  width: clamp(200px, 100vw, 300px);\n  aspect-ratio: 1/1;\n  display: block;\n  margin: 0 auto;\n}\n.MyId-scanner-1KRwh .MyId-button-1bTSS {\n  margin: 0 auto;\n  background-color: #ee5f23 !important;\n  border: #ee5f23 !important;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  gap: 10px;\n  color: white;\n}\n.MyId-scanner-1KRwh .MyId-input-2Etkc {\n  padding: 10px;\n  width: clamp(100px, 100vw, 300px);\n  border-radius: 10px;\n  outline: 1px solid rgba(0, 0, 0, 0.326) !important;\n  border: #ee5f23;\n}\n.MyId-input-2Etkc::-webkit-outer-spin-button,\n.MyId-input-2Etkc::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n.MyId-scanner-1KRwh .MyId-rotate-1PJRC {\n  -webkit-animation: MyId-rotate-1PJRC 1s linear infinite;\n          animation: MyId-rotate-1PJRC 1s linear infinite;\n}\n@-webkit-keyframes MyId-rotate-1PJRC {\n  from {\n    rotate: 0deg;\n  }\n  to {\n    rotate: 360deg;\n  }\n}\n@keyframes MyId-rotate-1PJRC {\n  from {\n    rotate: 0deg;\n  }\n  to {\n    rotate: 360deg;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"scanner": "MyId-scanner-1KRwh",
	"title": "MyId-title-18qus",
	"iframe": "MyId-iframe-r68_T",
	"button": "MyId-button-1bTSS",
	"input": "MyId-input-2Etkc",
	"rotate": "MyId-rotate-1PJRC"
};