import { gql } from "react-apollo";

import {
  ADMIN_UPDATE_STATIC_START,
  ADMIN_UPDATE_STATIC_SUCCESS,
  ADMIN_UPDATE_STATIC_ERROR,
} from "../../constants";

// Redirection
import history from "../../core/history";

// Toaster
import { toastr } from "react-redux-toastr";

const mutation = gql`
  mutation updateStaticPage(
    $id: Int
    $content: String
    $metaTitle: String
    $metaDescription: String
    $contentEn: String
    $metaTitleEn: String
    $metaDescriptionEn: String
    $contentRu: String
    $metaTitleRu: String
    $metaDescriptionRu: String

  ) {
    updateStaticPage(
      id: $id
      content: $content
      metaTitle: $metaTitle
      metaDescription: $metaDescription
      contentEn: $contentEn
      metaTitleEn: $metaTitleEn
      metaDescriptionEn: $metaDescriptionEn
      contentRu: $contentRu
      metaTitleRu: $metaTitleRu
      metaDescriptionRu: $metaDescriptionRu

    ) {
      status
    }
  }
`;

const query = gql`
  query getEditStaticPage($id: Int!) {
    getEditStaticPage(id: $id) {
      id
      pageName
      content
      metaTitle
      metaDescription
      contentEn
      metaTitleEn
      metaDescriptionEn
      contentRu
      metaTitleRu
      metaDescriptionRu
      createdAt
    }
  }
`;

export function updateStaticPageAction(values) {
  return async (dispatch, getState, { client }) => {
    try {
      dispatch({ type: ADMIN_UPDATE_STATIC_START });

      const { data } = await client.mutate({
        mutation,
        variables: {
          content: values.content,
          metaTitle: values.metaTitle,
          metaDescription: values.metaDescription,
          contentEn: values.contentEn,
          metaTitleEn: values.metaTitleEn,
          metaDescriptionEn: values.metaDescriptionEn,
          contentRu: values.contentRu,
          metaTitleRu: values.metaTitleRu,
          metaDescriptionRu: values.metaDescriptionRu,
          id: values.id,
        },
        refetchQueries: [{ query, variables: { id: values.id } }],
      });

      if (data.updateStaticPage.status === "success") {
        toastr.success(
          "Success!",
          "Static Content page has been updated successfully!"
        );
        history.push("/siteadmin/staticpage/management");
        dispatch({ type: ADMIN_UPDATE_STATIC_SUCCESS });
      } else {
        toastr.error("Error", "Updating failed");
        dispatch({ type: ADMIN_UPDATE_STATIC_ERROR });
      }
    } catch (error) {
      toastr.error("Failed!", "Failed to change  status");
      dispatch({ type: ADMIN_UPDATE_STATIC_ERROR });
      return false;
    }
    return true;
  };
}
