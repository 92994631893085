import React from "react";
import MyID from "./MyID";
import Layout from "../../components/Layout/Layout";

export default async function action() {
  return {
    title: "MyID",
    component: (
      <Layout>
        <MyID />
      </Layout>
    ),
  };
}
