import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import styles from "./MyId.css";
import { Button, FormGroup } from "react-bootstrap";
import base64toFile from "../../helpers/base64ToFile";
import axios from "axios";
import { connect } from "react-redux";
import history from "../../core/history";
import { loadAccount } from "../../actions/account";
import { setRuntimeVariable } from "../../actions/runtime";
import { toastr } from "react-redux-toastr";
// import { AiOutlineLoading3Quarters } from "react-icons/ai";

class MyID extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      pinfl: "",
      loading: false,
    };
  }

  componentDidMount() {
    window.addEventListener("message", this.sendRegistration.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.sendRegistration.bind(this));
  }
  sendRegistration(e) {
    if (e.data.cmd === 2 && e.data.photo_from_camera) {
      const photo_from_camera = e.data.photo_from_camera;

      const file = base64toFile(
        photo_from_camera.replace("data:image/png;base64,", ""),
        "image.png",
        "image/png"
      );
      const searchParams = new URLSearchParams(window.location.search);

      const formData = new FormData();
      const verificationCode = searchParams.get("code");
      const phone = searchParams.get("phone");

      formData.append("file", file);
      formData.append("phone", phone);
      formData.append("code", verificationCode);
      formData.append("pinfl", this.state.pinfl);
      this.setState({ ...this.state, loading: true });
      axios
        .post(`/auth/myid`, formData)
        .then((res) => {
          this.props.dispatch(loadAccount(true, "/dashboard"));
          this.props.dispatch(
            setRuntimeVariable({
              name: "isAuthenticated",
              value: true,
            })
          );
        })
        .catch((err) => {
          console.log({ err });
          let errorMessage;
          switch (err.response.data.error) {
            case "note":
              errorMessage = err.response.data.note;
              break;
            case "register":
              errorMessage = "Registratsiya qismidan o'tmagansiz!";
              break;
            case "otp":
              errorMessage = "Jo'natilgan kodimizni xato kiritdingiz";
              break;
            default:
              errorMessage = "Ma'lumotlaringizni xato kiritdingiz!";
          }
          errorMessage && toastr.error("Error!", errorMessage);

          history.push("/register");
        })
        .finally(() => {
          this.setState({ ...this.state, loading: false });
        });
    }
  }
  render() {
    const buttonClick = () => {
      //  if (!this.state.pinfl) {
      //   return toastr.error("Error!", "Enter the PINFL");
      // }
      // if (this.state.pinfl.length != 14) {
      //   return toastr.error("Error!", "PINFL must contain 14 number");
      // }
      this.ref.current.contentWindow.postMessage(
        {
          cmd: "open",
          config: { allowOnMobile: true, locale: "ru" },
        },
        "*"
      );
    };

    return (
      <div className={styles.scanner}>
        <div className={styles.title}>
          <h1>MyID Integratsiyasiga xush kelibsiz </h1>
          <p>
            Yuzingizni kameraga mo'ljallang va kamerani yoqish tugmasini bosing
          </p>
        </div>{" "}
        <iframe
          className={styles.iframe}
          ref={this.ref}
          id="myid_iframe"
          src="https://docs.myid.uz/iframe"
          allow="camera;fullscreen"
          allowFullScreen
          border="0"
        ></iframe>
        {/* <input
          type="number"
          value={this.state.pinfl}
          onChange={(e) => {
            this.setState({ ...this.state, pinfl: e.target.value });
          }}
          placeholder="PINFL"
          className={styles.input}
        /> */}
        <Button
          onClick={buttonClick}
          className={styles.button}
          disabled={this.state.loading}
        >
          {/* <AiOutlineLoading3Quarters className={styles.rotate} /> */}
          {this.state.loading ? "Jo'natilyapti..." : "Skanerlash"}
        </Button>
      </div>
    );
  }
}
export default connect()(withStyles(styles)(MyID));
